<template>
  <div class="p-4">
    <h4 class="mb-2">
      Accettazione termini privacy GDPR
    </h4>
    <b-alert
      variant="danger"
      show
    >
      <div class="alert-body">
        <p>
          <strong>Attenzione: </strong>
          <span>I termini sotto stanti sono necessari per l'utilizzo della piattaforma</span>
        </p>
      </div>
    </b-alert>
    <p>
      Cliccando su accetta confermi di aderire alla <b-link
        href="https://www.iubenda.com/privacy-policy/18917643"
        target="_blank"
      >
        privacy policy
      </b-link> e di aver accettato e firmato i termini e condizioni di Coffix Italia S.R.L.
    </p>

    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="logout"
      >
        Non Accetto
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="submitPrivacy"
      >
        Accetta
      </b-button>
    </div>

  </div>
</template>

<script>
import { BAlert, BLink, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import router from '@/router'
import { getUserData } from '@/auth/utils'
import CryptoJS from 'crypto-js'

export default {
  components: {
    BAlert,
    BLink,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const submitPrivacy = () => new Promise(() => {
      axios
        .post('accept-privacy', {})
        .then(response => {
          const userData = Object.assign(getUserData(), response.data.data)
          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(userData), process.env.VUE_APP_CRYPT)
            .toString())
          router.replace({ name: 'dashboard' })
        })
        .catch(error => {
          console.log(error)
        })
    })

    return {
      submitPrivacy,
    }
  },
}
</script>
